import { registerComponent } from 'saddlebag-componentregistry';

import App from './App';
import './public-path';

const { version, dynamics, pagetype, url } = window.TAG_MANAGER_PROPS;

registerComponent({
  name: 'js-tag-manager',
  version,
  componentDefinition: App,
  props: { dynamics, pagetype, url },
});
