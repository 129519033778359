const getIfIata = id => ((id || '').length === 3 ? id : undefined);

const getIataLocation = props => {
  const originIata =
    getIfIata(props.originIata) ||
    getIfIata(props.originAirport) ||
    getIfIata(props.originId) ||
    getIfIata(props.originCityId);
  const destinationIata =
    getIfIata(props.destinationIata) ||
    getIfIata(props.destinationAirport) ||
    getIfIata(props.destinationId) ||
    getIfIata(props.destinationCityId);

  return {
    originIata,
    destinationIata,
  };
};

export default getIataLocation;
