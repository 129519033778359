/* eslint-disable import/no-extraneous-dependencies */
const sanitisePreferences = preferences => {
  const newPreferences = { ...preferences };
  Object.keys(newPreferences).forEach(preference => {
    if (typeof newPreferences[preference] !== 'boolean') {
      newPreferences[preference] = undefined;
    }
  });
  return newPreferences;
};

const sanitiseProps = props => {
  const consentTypes = ['consentInformation', 'consentAdverts', 'isApp'];
  const newProps = { ...props };
  consentTypes.forEach(consentType => {
    if (typeof newProps[consentType] !== 'boolean') {
      newProps[consentType] = undefined;
    }
  });
  return newProps;
};

const sanitiseData = (props, preference) => [sanitiseProps(props), sanitisePreferences(preference)];

const shouldLoadTag = (props, preferences, category) => {
  let returnValue;
  const [cleanedProps, cleanedPreferences] = sanitiseData(props, preferences);

  if (category === 'essential') {
    returnValue = true;
  } else if (!(cleanedProps.isApp === false)) {
    if (category === 'information') {
      if (typeof cleanedProps.consentInformation === 'boolean') {
        returnValue = cleanedProps.consentInformation;
      } else {
        returnValue = cleanedPreferences[category];
      }
    } else if (category === 'adverts') {
      if (typeof cleanedProps.consentAdverts === 'boolean') {
        returnValue = cleanedProps.consentAdverts;
      } else {
        returnValue = cleanedPreferences[category];
      }
    }
  } else if (cleanedProps.isApp === false) {
    if (typeof cleanedPreferences[category] === 'boolean') {
      returnValue = cleanedPreferences[category];
    } else {
      returnValue = false;
    }
  }

  return returnValue === true;
};

export default shouldLoadTag;
