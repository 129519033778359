/* eslint-disable import/no-extraneous-dependencies */
import { logError as saddlebagLogError, logWarn as saddlebagLogWarn } from 'saddlebag-logger';

const logWarning = message =>
  saddlebagLogWarn({
    component: 'tag-manager',
    message,
  });

const logError = (error, message) =>
  saddlebagLogError(error, {
    component: 'tag-manager',
    message,
  });

export { logWarning, logError };
