/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { injectScript } from 'saddlebag-browser';
import { getPreferences } from 'saddlebag-tracking-preferences';
import observer from 'saddlebag-observer';
import logger from 'saddlebag-logger';

import loadDynamicTag from '../dynamic-tag-loader';
import getIataLocation from '../services/geo';
import { name as componentName, version as componentVersion } from '../../package.json';

import shouldLoadTag from './shouldLoadTag';
import { logError, logWarning } from './logger';

const getTagProps = props => {
  const copy = { ...props };
  delete copy.dynamics;
  return copy;
};

const loadEachTags = async props => {
  const { dynamics } = props;
  const preferences = getPreferences(props.market);
  const iataLocation = getIataLocation(props);

  let numberOfTagsTriedToLoad = 0;

  const events = await Promise.all(
    dynamics.map(async tag => {
      if (!tag.triedToLoad && shouldLoadTag(props, preferences, tag.category)) {
        try {
          tag.triedToLoad = true;
          numberOfTagsTriedToLoad += 1;
          await loadDynamicTag(tag.name, getTagProps(props), iataLocation, injectScript);
          return {
            tag: tag.name,
            category: tag.category,
            page: props.pagetype,
          };
        } catch (err) {
          logError(err, `Failed to inject '${tag.name}' tag`);
        }
      }
      return undefined;
    }),
  );

  const correctlyLoadedTags = events.filter(obj => !!obj);

  if (correctlyLoadedTags.length > 0) {
    for (let i = 0; i < correctlyLoadedTags.length; i += 1) {
      logger.logOperationalEvent({
        eventName: 'tag_launches',
        component: componentName,
        version: componentVersion,
        tag_name: correctlyLoadedTags[i].tag,
        tag_category: correctlyLoadedTags[i].category,
        tag_page: correctlyLoadedTags[i].page,
      });
    }
  }

  if (numberOfTagsTriedToLoad) {
    observer.publish('tags-loaded');
  } else {
    logWarning(`loadTags did not try to load any tags (received ${dynamics.length} dynamic tags)`);
  }
};

const loadTags = async (pageLoaded, tagManagerProps, loadTagsNotCalledTimeout) => {
  if (!pageLoaded) {
    return;
  }

  try {
    clearTimeout(loadTagsNotCalledTimeout);
    await loadEachTags(tagManagerProps);
  } catch (err) {
    logError(err, 'Failed to load tags');
  }
};

export { loadEachTags, loadTags };
